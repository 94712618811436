import logo from './media/viper-logo.png'
import './App.css'
import { Button, Grid, Typography } from '@mui/material'

function App() {
  return (
    <Grid container direction='column' p={3}>
      <Grid item container xs={12} rowGap={6} direction='column' alignItems='center' justifyContent='center' sx={{backgroundColor:'#000624', color:'#FFF', minHeight:'100vh'}}>
        <Grid item><Typography variant='h1' fontSize={{md:130, xs:45}} fontFamily='monospace'>VIPER DIGITAL</Typography></Grid>
        <Grid item><img src={logo} className="App-logo" alt="logo" /></Grid>
        <Grid item container justifyContent='space-around' xs={12} rowGap={12}>
          <Grid item container direction='column' alignItems='center' xs={11} md={6}>
            <Grid item>
              <Button href='https://jtm-fitness.web.app' sx={{textDecoration:'underline', backgroundColor:'transparent', color:'#FFF', '&:hover':{textDecoration:'underline', backgroundColor:'transparent', color:'#49E20C'}}}><Typography fontSize={{md:60, xs:20}}>JTM Fitness</Typography></Button>
            </Grid>
            <Grid item overflow='hidden' width='100%'>
              <iframe src='https://jtm-fitness.web.app/' width='100%' height={550} style={{overflow:'hidden'}}></iframe>
            </Grid>
          </Grid>
          <Grid item container direction='column' alignItems='center' xs={11} md={6}>
            <Grid item>
              <Button href='https://zoe-inc-and-associates.web.app/' sx={{textDecoration:'underline', backgroundColor:'transparent', color:'#FFF', '&:hover':{textDecoration:'underline', backgroundColor:'transparent', color:'#49E20C'}}}><Typography fontSize={{md:60, xs:20}}>ZOE Inc</Typography></Button>
            </Grid>
            <Grid item overflow='hidden' width='95%'>
              <iframe src='https://zoe-inc-and-associates.web.app/' width='100%' height={550} style={{overflow:'hidden'}}></iframe>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default App;
